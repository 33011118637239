import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Button,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  Snackbar,
  Theme,
  Typography,
} from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { useKeycloak } from "@react-keycloak/web";
import DateConstants from "Constants/DateConstants";
import TimeConstants from "Constants/TimeConstants";
import Docxtemplater from "docxtemplater";
import { Roles } from "Enums/Roles";
import { SubSystemType } from "Enums/SubSystemType";
import { saveAs } from "file-saver";
import {
  GET_ALL_CURRENT_AUDIT_DATA_BY_FPSO_AREA,
  GET_CURRENT_AUDIT_BY_FPSO_AREA,
  GetAllCurrentAuditDataByFpsoAreaQueryResult,
  GetAllCurrentAuditDataByFpsoAreaQueryVariables,
  GetCurrentAuditByFpsoAreaQueryResult,
  GetCurrentAuditByFpsoAreaQueryVariables,
  INSERT_AUDIT,
  InsertAuditQueryResult,
  TAKE_AUDIT_SNAPSHOT,
  UPDATE_AUDIT,
  UPDATE_AUDIT_LOCK_STATUS,
  UPDATE_CURRENT_AUDIT_FOR_FPSO_AREA,
} from "Graphql/AuditQueries";
import {
  DELETE_SUB_SYSTEM_ITEMS_BY_ITEM_ID,
  INSERT_SUB_SYSTEM_ITEM,
} from "Graphql/FpsoQueries";
import { CheckboxOption } from "Logic/SubSystemOptions/CheckboxOption";
import { CustomStylesSubSystemOptions } from "Logic/SubSystemOptions/CustomStylesSubSystemOptions";
import { SubSystemOptionsLogic } from "Logic/SubSystemOptions/SubSystemOptionsLogic";
import { Audit } from "Models/Audit";
import { OptionType } from "Models/OptionType";
import { RouteParams } from "Models/RouteParams";
import moment from "moment";
import PizZip from "pizzip";
import React, { FC, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Select, { MultiValue } from "react-select";
import { FileUtils } from "Utils/FileUtils";
import { AddCarButton } from "Views/Components/AuditOverview/AddCarButton";
import { AuditDetails } from "Views/Components/AuditOverview/AuditDetails";
import { CreateAuditButton } from "Views/Components/AuditOverview/CreateAuditButton";
import { CreateSnapshotButton } from "Views/Components/AuditOverview/CreateSnapshotButton";
import { CarList } from "Views/Components/CarList/CarList";
import { WordDocumentDownloadWhiteIcon } from "../Icons/WordDocumentDownloadWhiteIcon";
import { DownloadFileButton } from "./DownloadFileButton";
import { LockAuditButton } from "./LockAuditButton";
import axios from "axios";

const PizZipUtils = require("pizzip/utils/index");
const expressionParser = require("docxtemplater/expressions.js");

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function loadFile(url: string, callback: (error: any, content: any) => void) {
  PizZipUtils.getBinaryContent(url, callback);
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    beginNewAuditButton: {
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: theme.spacing(2),
    },
    snapshotButton: {
      display: "flex",
      marginBottom: theme.spacing(2),
    },
    dropdown: {
      display: "flex",
      marginBottom: theme.spacing(2),
      minWidth: 200,
    },
    lockAuditText: {
      textTransform: "capitalize",
      color: "white",
    },
  }),
);

export const CurrentAuditOverview: FC = () => {
  const { fpsoAreaId } = useParams<RouteParams>();
  const classes = useStyles();
  const { t } = useTranslation(["audit", "errors"]);
  const { keycloak } = useKeycloak();
  const uuidv4 = require("uuid/v4");

  const [snapshotSuccessOpen, setSnapshotSuccessOpen] = useState(false);
  const [snapshotFailureOpen, setSnapshotFailureOpen] = useState(false);
  const [carAddFormEnabled, setCarAddFormEnabled] = useState(false);
  const [selectedSubSystems, setSelectedSubSystems] = useState<
    MultiValue<OptionType>
  >();
  const [downloadUnavailableOpen, setDownloadUnavailableOpen] = useState(false);

  const {
    loading: auditLoading,
    error: auditError,
    data: auditData,
    refetch,
  } = useQuery<
    GetCurrentAuditByFpsoAreaQueryResult,
    GetCurrentAuditByFpsoAreaQueryVariables
  >(GET_CURRENT_AUDIT_BY_FPSO_AREA, {
    variables: {
      fpsoAreaId: fpsoAreaId || "undefined",
    },
  });

  const [createAudit, { error: insertAuditError }] = useMutation<
    InsertAuditQueryResult
  >(INSERT_AUDIT);
  const [updateAudit, { error: updateAuditError }] = useMutation(UPDATE_AUDIT);
  const [createSnapshot] = useMutation(TAKE_AUDIT_SNAPSHOT);
  const [setCurrentAudit] = useMutation(UPDATE_CURRENT_AUDIT_FOR_FPSO_AREA);
  const [deleteSubSystemItem] = useMutation(DELETE_SUB_SYSTEM_ITEMS_BY_ITEM_ID);
  const [addSubSystemItem] = useMutation(INSERT_SUB_SYSTEM_ITEM);
  const [updateAuditLockStatus] = useMutation(UPDATE_AUDIT_LOCK_STATUS);

  const createSnapshotFromAudit = (
    audit: Audit | undefined,
    snapshotDate: Date,
  ) => {
    if (!audit) {
      return;
    }
    if (keycloak?.tokenParsed?.sub) {
      const snapshotId = uuidv4();
      createSnapshot({
        variables: {
          id: snapshotId,
          auditId: audit.id,
          auditReference: audit.auditReference,
          auditScore: audit.auditScore,
          startDate: audit.startDate,
          endDate: audit.endDate,
          fpsoAreaId: audit.fpsoAreaId,
          leadAuditorId: audit.leadAuditor.id,
          englishAuditTranslationSnapshotId: uuidv4(),
          portugueseAuditTranslationSnapshotId: uuidv4(),
          auditSummaryEnglish: audit.englishTranslation[0]?.auditSummary,
          auditSummaryPortuguese: audit.portugueseTranslation[0]?.auditSummary,
          mainFindingsEnglish: audit.englishTranslation[0]?.mainFindings,
          mainFindingsPortuguese: audit.portugueseTranslation[0]?.mainFindings,
          professionalOpinionEnglish:
            audit.englishTranslation[0]?.professionalOpinion,
          professionalOpinionPortuguese:
            audit.portugueseTranslation[0]?.professionalOpinion,
          actionsRecommendationsEnglish:
            audit.englishTranslation[0]?.actionsRecommendations,
          actionsRecommendationsPortuguese:
            audit.portugueseTranslation[0]?.actionsRecommendations,
          createdAt: snapshotDate,
        },
      }).then(result => {
        audit.itemsWithSubSystems.forEach(item => {
          addSubSystemItem({
            variables: {
              itemId: snapshotId,
              subSystemType: item.subSystemType,
            },
          }).then(() => {
            refetch({
              fpsoAreaId: fpsoAreaId || "undefined",
            });
          });
        });

        if (result.errors) {
          setSnapshotFailureOpen(true);
        } else {
          setSnapshotSuccessOpen(true);
        }
      });
    }
  };

  const handleNewAuditClick = (beginNewAuditDate: Date) => {
    // if (auditData?.fpsoArea.currentAudit.isLocked === false) {
    createSnapshotFromAudit(
      auditData?.fpsoArea.currentAudit,
      beginNewAuditDate,
    );

    let auditReference =
      auditData?.fpsoArea.fpso.name +
      "-" +
      auditData?.fpsoArea.areaName +
      "-" +
      moment(beginNewAuditDate).format(DateConstants.AUDIT_TITLE_DATE_FORMAT);
    auditReference = auditReference.replace(new RegExp(" ", "g"), "-");

    if (keycloak?.tokenParsed?.sub) {
      createAudit({
        variables: {
          id: uuidv4(),
          fpsoAreaId,
          auditReference,
          leadAuditorId: keycloak.tokenParsed?.sub,
          createdAt: beginNewAuditDate,
        },
      }).then(({ data }) => {
        setCurrentAudit({
          variables: {
            fpsoAreaId,
            newCurrentAuditId: data?.result.newAudit[0].id,
            oldCurrentAuditId:
              data?.result.newAudit[0].fpso_area.currentAuditId,
          },
        }).then(() => {
          refetch({
            fpsoAreaId: fpsoAreaId || "undefined",
          }).then(({ data: currentData }) => {
            createSnapshotFromAudit(
              currentData.fpsoArea.currentAudit,
              beginNewAuditDate,
            );
          });
        });
      });
    }
    // }
  };

  const handleNewSnapshotClick = (snapshotAudit: Date) => {
    if (keycloak?.tokenParsed?.sub) {
      createSnapshotFromAudit(auditData?.fpsoArea.currentAudit, snapshotAudit);
    }
  };

  const handleAuditEdit = (
    updatedAudit: Audit,
    updatedSubSystems: SubSystemType[],
    changeInSubSystems: boolean,
  ) => {
    if (auditData?.fpsoArea.currentAudit.isLocked === false) {
      updateAudit({
        variables: {
          id: updatedAudit.id,
          startDate: updatedAudit.startDate,
          endDate: updatedAudit.endDate,
          englishTranslationId:
            updatedAudit.englishTranslation[0]?.id || uuidv4(),
          auditSummaryEnglish: updatedAudit.englishTranslation[0]?.auditSummary,
          portugueseTranslationId:
            updatedAudit.portugueseTranslation[0]?.id || uuidv4(),
          auditSummaryPortuguese:
            updatedAudit.portugueseTranslation[0]?.auditSummary,
          mainFindingsEnglish: updatedAudit.englishTranslation[0]?.mainFindings,
          mainFindingsPortuguese:
            updatedAudit.portugueseTranslation[0]?.mainFindings,
          professionalOpinionEnglish:
            updatedAudit.englishTranslation[0]?.professionalOpinion,
          professionalOpinionPortuguese:
            updatedAudit.portugueseTranslation[0]?.professionalOpinion,
          actionsRecommendationsEnglish:
            updatedAudit.englishTranslation[0]?.actionsRecommendations,
          actionsRecommendationsPortuguese:
            updatedAudit.portugueseTranslation[0]?.actionsRecommendations,
        },
      })
        .then(() => {
          if (changeInSubSystems === true) {
            deleteSubSystemItem({
              variables: {
                itemId: updatedAudit.id,
              },
            });
          } else {
            refetch({
              fpsoAreaId: fpsoAreaId || "undefined",
            });
          }
        })
        .then(() => {
          if (changeInSubSystems === true) {
            updatedSubSystems.forEach(item => {
              addSubSystemItem({
                variables: {
                  itemId: updatedAudit.id,
                  subSystemType: item,
                },
              }).then(() => {
                refetch({
                  fpsoAreaId: fpsoAreaId || "undefined",
                });
              });
            });
          }
        });
    }
  };

  const refetchAudit = () => {
    refetch({
      fpsoAreaId: fpsoAreaId || "undefined",
    });
  };

  const handleSnapshotSuccessClose = (
    event?: SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnapshotSuccessOpen(false);
  };

  const handleSnapshotFailureClose = (
    event?: SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnapshotFailureOpen(false);
  };

  const handleSetCarAddFormEnabled = () => {
    setCarAddFormEnabled(!carAddFormEnabled);
  };

  let auditLockedStatus = false;
  if (auditData?.fpsoArea.currentAudit) {
    auditLockedStatus = auditData.fpsoArea.currentAudit.isLocked;
  }

  const handleAuditLocked = () => {
    if (
      keycloak?.hasRealmRole(Roles.auditor) ||
      keycloak?.hasRealmRole(Roles.administrator)
    ) {
      if (auditData?.fpsoArea.currentAudit.isLocked === false) {
        // audit unlocked to locked

        updateAuditLockStatus({
          variables: {
            id: auditData.fpsoArea.currentAudit.id,
            isLocked: true,
            lockingUserId: keycloak?.tokenParsed?.sub,
          },
        }).then(() => {
          refetch({
            fpsoAreaId: fpsoAreaId || "undefined",
          });
        });
      } else if (auditData?.fpsoArea.currentAudit.isLocked === true) {
        // audit locked to unlocked

        updateAuditLockStatus({
          variables: {
            id: auditData.fpsoArea.currentAudit.id,
            isLocked: false,
            lockingUserId: keycloak?.tokenParsed?.sub,
          },
        }).then(() => {
          refetch({
            fpsoAreaId: fpsoAreaId || "undefined",
          });
        });
      }
    }
  };

  const handleDownloadFileClick = (fileTypeName: string) => {
    // setDownloadUnavailableOpen(true);
    let url: string;
    let fileName: string;

    url = "export/AuditReportTemplate.docx"; // + fileTypeName;
    fileName = fileTypeName;

    if (url) {
      FileUtils.downloadFileByUrl(url, fileName, `bearer ${keycloak?.token}`);
    }
  };

  // let documentData: string;

  // const iterate = (obj: FPSOArea | any, parentKey: string) => {
  //   if (isNaN(+parentKey)) {
  //     documentData += parentKey + ": [ {";
  //   }
  //   Object.keys(obj).forEach(key => {
  //     if (key.indexOf("_typename") === -1) {
  //       if (obj[key as keyof FPSOArea] !== null) {
  //         if (
  //           (": " + obj[key as keyof FPSOArea]).indexOf("[object Object]") ===
  //             -1 &&
  //           ": " + obj[key as keyof FPSOArea] !== ": "
  //         ) {
  //           documentData += key + ': "' + obj[key as keyof FPSOArea] + '",';
  //         }

  //         if (typeof obj[key as keyof FPSOArea] === "object") {
  //           iterate(obj[key as keyof FPSOArea], key);
  //         }
  //       } else {
  //         documentData += key + ': "N/A",';
  //       }
  //     }
  //   });
  //   if (isNaN(+parentKey)) {
  //     documentData += "} ], ";
  //   } else {
  //     documentData += "}, {";
  //   }
  // };
  const { data: allAuditData } = useQuery<
    GetAllCurrentAuditDataByFpsoAreaQueryResult,
    GetAllCurrentAuditDataByFpsoAreaQueryVariables
  >(GET_ALL_CURRENT_AUDIT_DATA_BY_FPSO_AREA, {
    variables: {
      fpsoAreaId: fpsoAreaId || "undefined",
    },
  });

  // Generate document code 

  const generateDocument = () => {
    loadFile(
      "https://cellar-c2.services.clever-cloud.com/ogc-audit-software/Audit%20Report%20Template%20for%20Doc%20Generation%205_1_2023.docx",
      function(error, content) {
        if (error) {
          throw error;
        }
        const zip = new PizZip(content);
        const doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
          parser: expressionParser,
        });

        // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
        doc.render(allAuditData);
        const out = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        }); // Output the document using Data-URI
        const outputDocTitle =
          auditData?.fpsoArea.fpso.name +
          " - " +
          auditData?.fpsoArea.areaName +
          " Audit Report.docx";
        saveAs(out, outputDocTitle);
      },
    );
  };

  const handleDownloadFileAlertClose = (
    event?: SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setDownloadUnavailableOpen(false);
  };

  const subSystemOptionsBlank: OptionType[] = [];
  const subSystemOptions = SubSystemOptionsLogic(
    auditData?.fpsoArea.areaName,
    subSystemOptionsBlank,
  );





  const handleTestEmail = () => {
    axios
    .post(
      // `${process.env.REACT_APP_NOTIFICATIONS_API_URL}
      `https://ogc-api-dev.cloud.whyellow.nl/api/v1/notifications/test`, {
      headers: {
        Authorization: `bearer ${keycloak?.token}`,
      },
    })
  }

  const handleChange = (option: MultiValue<OptionType>) => {
    setSelectedSubSystems(option);
  };

  const Option = CheckboxOption;

  if (auditLoading) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  if (auditError || updateAuditError) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body1" color="error">
            {t("errors:auditFetchError")}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  if (insertAuditError) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body1" color="error">
            {t("errors:auditInsertError")}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container>
      {(keycloak?.hasRealmRole(Roles.auditor) ||
        keycloak?.hasRealmRole(Roles.administrator)) && (
        <>
          <Grid item xs={6} className={classes.snapshotButton}>
            {auditData?.fpsoArea.currentAudit && (
              <>
                <CreateSnapshotButton createSnapshot={handleNewSnapshotClick} />

                {auditData?.fpsoArea.currentAudit.isLocked === false && (
                  <AddCarButton
                    setCarAddFormEnabled={handleSetCarAddFormEnabled}
                  />
                )}
                <LockAuditButton
                  handleLockAudit={handleAuditLocked}
                  auditLockStatus={auditLockedStatus}
                />

                <Button onClick={() => handleTestEmail()}/>

                {auditData?.fpsoArea.currentAudit.isLocked === true && (
                  <Typography variant="body1" className={classes.lockAuditText}>
                    {t("audits:auditIsLockedBy") +
                      auditData.fpsoArea.currentAudit.lockingUser.fullName +
                      ". " +
                      t("audits:viewOnlyMode")}
                  </Typography>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={6} className={classes.beginNewAuditButton}>
            {auditData?.fpsoArea.currentAudit ? (
              <>
                <DownloadFileButton
                  downloadFile={handleDownloadFileClick}
                  fileType={"AuditReport"}
                />
                <DownloadFileButton
                  downloadFile={handleDownloadFileClick}
                  fileType={"MeetingMinutes"}
                />
                <Button onClick={() => generateDocument()}>
                  <WordDocumentDownloadWhiteIcon />
                </Button>

                {auditData?.fpsoArea.currentAudit.isLocked === false && (
                  <CreateAuditButton createNewAudit={handleNewAuditClick} />
                )}
              </>
            ) : (
              <CreateAuditButton createNewAudit={handleNewAuditClick} />
            )}
          </Grid>
        </>
      )}
      {auditData?.fpsoArea?.currentAudit ? (
        <>
          <Grid item xs={12}>
            <AuditDetails
              audit={auditData.fpsoArea.currentAudit}
              isArchived={false}
              handleAuditEdited={handleAuditEdit}
              fpsoAreaName={auditData.fpsoArea.areaName}
              fpsoName={auditData.fpsoArea.fpso.name}
              refetchAudit={refetchAudit}
            />
          </Grid>
          <Grid item xs={10} className={classes.dropdown}>
            <Select
              onChange={option => handleChange(option)}
              options={subSystemOptions}
              isMulti
              autoFocus
              isSearchable
              isClearable
              styles={CustomStylesSubSystemOptions}
              placeholder={t("fpsos:helperTextCARsFilterSubSystems")}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{
                Option,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <CarList
              audit={auditData.fpsoArea.currentAudit}
              carAddFormEnabled={carAddFormEnabled}
              setCarAddFormDisabled={handleSetCarAddFormEnabled}
              selectedSubSystems={selectedSubSystems}
              fpsoAreaName={auditData.fpsoArea.areaName}
              auditLockStatus={auditData.fpsoArea.currentAudit.isLocked}
            />
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          {t("errors:noCurrentAuditFound")}
        </Grid>
      )}
      <Snackbar
        open={snapshotSuccessOpen}
        autoHideDuration={TimeConstants.SNACKBAR_ALERT_DURATION}
        onClose={handleSnapshotSuccessClose}
      >
        <Alert onClose={handleSnapshotSuccessClose} severity="success">
          {t("audits:snapshotSuccess")}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snapshotFailureOpen}
        autoHideDuration={TimeConstants.SNACKBAR_ALERT_DURATION}
        onClose={handleSnapshotFailureClose}
      >
        <Alert onClose={handleSnapshotFailureClose} severity="error">
          {t("audits:snapshotFailure")}
        </Alert>
      </Snackbar>
      <Snackbar
        open={downloadUnavailableOpen}
        autoHideDuration={TimeConstants.SNACKBAR_ALERT_DURATION}
        onClose={handleDownloadFileAlertClose}
      >
        <Alert onClose={handleDownloadFileAlertClose} severity="error">
          {t("audits:downloadUnavailable")}
        </Alert>
      </Snackbar>
    </Grid>
  );
};
