import { useMutation } from "@apollo/react-hooks";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import PlaylistPlayIcon from "@material-ui/icons/PlaylistPlay";
import VideoCallIcon from "@material-ui/icons/VideoCall";
import VideocamIcon from "@material-ui/icons/Videocam";
import { Alert } from "@material-ui/lab";
import { useKeycloak } from "@react-keycloak/web";
import DateConstants from "Constants/DateConstants";
import LanguageCodes from "Constants/LanguageCodes";
import Routes from "Constants/Routes";
import TimeConstants from "Constants/TimeConstants";
import { Roles } from "Enums/Roles";
import { SubSystemType } from "Enums/SubSystemType";
import {
  END_AUDIT_VIDEO_SESSION,
  EndAuditVideoSessionMutationResult,
  INSERT_AUDIT_VIDEO_SESSION,
  InsertAuditVideoSessionMutationResult,
} from "Graphql/AuditQueries";
import i18next from "i18next";
import { Audit } from "Models/Audit";
import moment from "moment";
import React, { FC, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AuditDetailsEdit } from "Views/Components/AuditOverview/AuditDetailsEdit";
import { ArchivedAuditIcon } from "Views/Components/Icons/ArchivedAuditIcon";
import { EditAuditInfoIcon } from "Views/Components/Icons/EditAuditInfoIcon";

interface Props {
  readonly audit: Audit;
  readonly isArchived: boolean;
  handleAuditEdited?(
    updatedAudit: Audit,
    updatedSubSystems: SubSystemType[],
    changeInSubSystems: boolean,
  ): void;
  readonly fpsoAreaName: string;
  readonly fpsoName: string;
  refetchAudit(): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    button: {
      paddingTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    editButtonRow: {
      display: "flex",
      justifyContent: "space-between",
    },
    title: {
      display: "flex",
      justifyContent: "space-between",
    },
    executiveSummary: {
      paddingBottom: theme.spacing(1),
      paddingRight: theme.spacing(1),
      whiteSpace: "pre-line",
    },
    readLess: {
      overflow: "hidden",
      height: 48,
      textOverflow: "ellipsis",
      width: "100%",
    },
    buttonText: {
      textTransform: "capitalize",
      fontStyle: "italic",
    },
    summaryHeader: {
      fontWeight: "bold",
    },
    executiveSummaryHeader: {
      textDecoration: "underline",
    },
    subSystemItem: {
      padding: theme.spacing(1),
    },
  }),
);

export const AuditDetails: FC<Props> = ({
  audit,
  isArchived,
  handleAuditEdited: handleEditedAuditReceived,
  fpsoAreaName,
  fpsoName,
  refetchAudit,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(["audits", "fpsos"]);
  const { keycloak } = useKeycloak();
  const history = useHistory();
  const uuidv4 = require("uuid/v4");

  const [
    videoSessionStartSuccessOpen,
    setVideoSessionStartSuccessOpen,
  ] = useState(false);
  const [
    videoSessionStartFailureOpen,
    setVideoSessionStartFailureOpen,
  ] = useState(false);

  const [videoSessionEndSuccessOpen, setVideoSessionEndSuccessOpen] = useState(
    false,
  );
  const [videoSessionEndFailureOpen, setVideoSessionEndFailureOpen] = useState(
    false,
  );

  const [editEnabled, setEditEnabled] = useState(false);
  const [auditSummaryReadMore, setAuditSummaryReadMore] = useState(false);
  const [mainFindingsReadMore, setMainFindingsReadMore] = useState(false);
  const [
    professionalOpinionReadMore,
    setProfessionalOpinionReadMore,
  ] = useState(false);
  const [
    actionsRecommendationsReadMore,
    setActionsRecommendationsReadMore,
  ] = useState(false);

  const handleEditClicked = () => {
    setEditEnabled(!editEnabled);
  };

  const handleAuditEdited = (
    updatedAudit: Audit,
    updatedSubSystems: SubSystemType[],
    changeInSubSystems: boolean,
  ) => {
    setEditEnabled(false);
    if (handleEditedAuditReceived) {
      handleEditedAuditReceived(
        updatedAudit,
        updatedSubSystems,
        changeInSubSystems,
      );
    }
  };

  const handleAuditSummaryReadMore = () => {
    setAuditSummaryReadMore(!auditSummaryReadMore);
  };

  const handleMainFindingsReadMore = () => {
    setMainFindingsReadMore(!mainFindingsReadMore);
  };

  const handleProfessionalOpinionReadMore = () => {
    setProfessionalOpinionReadMore(!professionalOpinionReadMore);
  };

  const handleActionsRecommendationsReadMore = () => {
    setActionsRecommendationsReadMore(!actionsRecommendationsReadMore);
  };

  const [createAuditVideoSession] = useMutation<
    InsertAuditVideoSessionMutationResult
  >(INSERT_AUDIT_VIDEO_SESSION);

  const [endAuditVideoSession] = useMutation<
    EndAuditVideoSessionMutationResult
  >(END_AUDIT_VIDEO_SESSION);

  const handleStartVideoSession = () => {
    if (keycloak?.tokenParsed?.sub) {
      const sessionId = uuidv4();
      createAuditVideoSession({
        variables: {
          id: sessionId,
          sessionCreatorId: keycloak.tokenParsed.sub,
          fpsoAreaId: audit.fpsoAreaId,
          auditId: audit.id,
        },
      }).then(result => {
        if (result.errors) {
          setVideoSessionStartFailureOpen(true);
        } else {
          const user = keycloak?.idTokenParsed?.sub;

          window
            .open(
              "https://ogc-audit-remote-signaling-proto.cleverapps.io/#" +
                "&user=" +
                user +
                "&session=" +
                sessionId +
                "&startCall=true" +
                "&shareLink=true",
              "_blank",
              "location=yes,height=570,width=520,scrollbars=yes,status=yes",
            )
            ?.focus();

          refetchAudit();

          setVideoSessionStartSuccessOpen(true);
        }
      });
    }
  };

  const handleJoinVideoSession = () => {
    if (keycloak?.tokenParsed?.sub) {
      const sessionId = audit.activeVideoSessions[0].id;
      const user = keycloak?.idTokenParsed?.sub;

      window
        .open(
          "https://ogc-audit-remote-signaling-proto.cleverapps.io/#" +
            "&user=" +
            user +
            "&session=" +
            sessionId +
            "&startCall=true" +
            "&shareLink=true",
          "_blank",
          "location=yes,height=570,width=520,scrollbars=yes,status=yes",
        )
        ?.focus();
    }
  };

  const handleEndVideoSession = () => {
    if (
      (audit.leadAuditor.id === keycloak?.idTokenParsed?.sub &&
        keycloak?.hasRealmRole(Roles.auditor)) ||
      keycloak?.hasRealmRole(Roles.administrator)
    ) {
      const sessionId = audit.activeVideoSessions[0].id;

      const ws = new WebSocket(
        "wss://ogc-audit-remote-signaling-proto.cleverapps.io",
      );

      ws.onopen = () => {
        ws.send(JSON.stringify({ type: "endsession", session: sessionId }));
      };

      endAuditVideoSession({
        variables: {
          id: sessionId,
        },
      }).then(result => {
        if (result.errors) {
          setVideoSessionEndFailureOpen(true);
        } else {
          refetchAudit();

          setVideoSessionEndSuccessOpen(true);
        }
      });
    }
  };

  const handleVideoSessionStartSuccessClose = (
    event?: SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setVideoSessionStartSuccessOpen(false);
  };

  const handleVideoSessionStartFailureClose = (
    event?: SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setVideoSessionStartFailureOpen(false);
  };

  const handleVideoSessionEndSuccessClose = (
    event?: SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setVideoSessionEndSuccessOpen(false);
  };

  const handleVideoSessionEndFailureClose = (
    event?: SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setVideoSessionEndFailureOpen(false);
  };

  const handleOpenVideoSessionList = () => {
    if (keycloak?.tokenParsed?.sub) {
      window.scrollTo(0, 0);
      history.push(
        `/fpsoArea/${audit.fpsoAreaId}/${Routes.CURRENT_AUDIT_BASEPATH}/${Routes.CURRENT_AUDIT_VIDEO_SESSIONS_BASEPATH}`,
      );
    }
  };

  return (
    <Paper className={classes.root}>
      {editEnabled ? (
        <Grid item xs={12} className={classes.editButtonRow}>
          <Typography variant="h6">{t("editTitle")} </Typography>
          <Tooltip title={t<string>("tooltipDiscard")} arrow={true}>
            <IconButton onClick={handleEditClicked}>
              <Typography variant="body1">
                {t("cars:discardChanges")}
              </Typography>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      ) : (!isArchived || audit.isActive) &&
        ((audit.leadAuditor.id === keycloak?.idTokenParsed?.sub &&
          keycloak?.hasRealmRole(Roles.auditor)) ||
          keycloak?.hasRealmRole(Roles.administrator)) ? (
        <Grid item xs={12} className={classes.editButtonRow}>
          <Typography variant="h6">
            {fpsoName + " - " + fpsoAreaName + ": " + t("title")}
          </Typography>

          <Grid item xs={3} className={classes.editButtonRow}>
            <Button onClick={handleOpenVideoSessionList}>
              <Tooltip
                title={"See Video Session List for " + fpsoAreaName}
                arrow={true}
              >
                <PlaylistPlayIcon style={{ fill: "black", fontSize: 35 }} />
              </Tooltip>
            </Button>
            {audit.activeVideoSessions.length === 0 ? (
              <Button onClick={handleStartVideoSession}>
                <Tooltip
                  title={"Start video session for " + fpsoAreaName}
                  arrow={true}
                >
                  <VideoCallIcon style={{ fill: "black", fontSize: 35 }} />
                </Tooltip>
              </Button>
            ) : (
              <>
                <Button onClick={handleJoinVideoSession}>
                  <Tooltip
                    title={"Join current video session for " + fpsoAreaName}
                    arrow={true}
                  >
                    <VideocamIcon style={{ fill: "red", fontSize: 35 }} />
                  </Tooltip>
                </Button>
                {(keycloak?.idTokenParsed?.sub ===
                  audit.activeVideoSessions[0].sessionCreator.id ||
                  keycloak?.hasRealmRole(Roles.administrator)) && (
                  <Button onClick={handleEndVideoSession}>
                    <Tooltip
                      title={"End current video session for " + fpsoAreaName}
                      arrow={true}
                    >
                      <VideocamIcon style={{ fill: "blue", fontSize: 35 }} />
                    </Tooltip>
                  </Button>
                )}
              </>
            )}
            {!isArchived && audit.isLocked === false && (
              <Tooltip title={t<string>("tooltipEdit")} arrow={true}>
                <Button onClick={handleEditClicked}>
                  <EditAuditInfoIcon />
                </Button>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      ) : (
        (!isArchived || audit.isActive) && (
          <Grid item xs={12} className={classes.editButtonRow}>
            <Typography variant="h6">
              {fpsoAreaName + ": " + t("title")}
            </Typography>

            {audit.activeVideoSessions.length > 0 && (
              <Button onClick={handleJoinVideoSession}>
                <Tooltip
                  title={"Join current video session for " + fpsoAreaName}
                  arrow={true}
                >
                  <VideocamIcon style={{ fill: "red", fontSize: 35 }} />
                </Tooltip>
              </Button>
            )}
          </Grid>
        )
      )}
      {isArchived && (
        <Grid item xs={12} className={classes.editButtonRow}>
          <Tooltip
            title={t<string>("archive:ArchivedAuditMessage")}
            arrow={true}
          >
            <div className={classes.title}>
              <ArchivedAuditIcon />
              <Typography variant="h6">
                {fpsoAreaName + ": " + t("archivedTitle")}
              </Typography>
            </div>
          </Tooltip>
          <Typography variant="body1">
            {t("archive:dateEntryCreated") +
              ": " +
              moment(audit.createdAt).format(DateConstants.TIME_DATE_FORMAT)}
          </Typography>
        </Grid>
      )}
      {editEnabled ? (
        <AuditDetailsEdit
          audit={audit}
          handleAuditEdited={handleAuditEdited}
          fpsoAreaName={fpsoAreaName}
        />
      ) : (
        <Grid container>
          <Grid item xs={9}>
            <Typography variant="h6" className={classes.executiveSummaryHeader}>
              {t("executiveSummary")}
            </Typography>
            <Typography variant="h5" className={classes.summaryHeader}>
              {t("auditSummary")}
            </Typography>
            <Typography variant="body1" className={classes.executiveSummary}>
              {!auditSummaryReadMore &&
              (audit.englishTranslation[0]?.auditSummary ||
                audit.portugueseTranslation[0]?.auditSummary) &&
              (audit.englishTranslation[0]?.auditSummary.length ||
                audit.portugueseTranslation[0]?.auditSummary.length) > 500 ? (
                <>
                  <Box className={classes.readLess}>
                    {i18next.language === LanguageCodes.ENGLISH
                      ? audit.englishTranslation[0]?.auditSummary ||
                        t("notAvailable")
                      : audit.portugueseTranslation[0]?.auditSummary ||
                        t("notAvailable")}
                  </Box>
                  <Button
                    onClick={handleAuditSummaryReadMore}
                    className={classes.buttonText}
                  >
                    <Typography variant="body1">{t("readMore")}</Typography>
                  </Button>
                </>
              ) : (
                <>
                  <Box>
                    {i18next.language === LanguageCodes.ENGLISH
                      ? audit.englishTranslation[0]?.auditSummary ||
                        t("notAvailable")
                      : audit.portugueseTranslation[0]?.auditSummary ||
                        t("notAvailable")}
                  </Box>
                  {auditSummaryReadMore && (
                    <Button
                      onClick={handleAuditSummaryReadMore}
                      className={classes.buttonText}
                    >
                      <Typography variant="body1">{t("readLess")}</Typography>
                    </Button>
                  )}
                </>
              )}
            </Typography>
            <Typography variant="h5" className={classes.summaryHeader}>
              {t("mainFindings")}
            </Typography>
            <Typography variant="body1" className={classes.executiveSummary}>
              {!mainFindingsReadMore &&
              (audit.englishTranslation[0]?.mainFindings ||
                audit.portugueseTranslation[0]?.mainFindings) &&
              (audit.englishTranslation[0]?.mainFindings.length ||
                audit.portugueseTranslation[0]?.mainFindings.length) > 500 ? (
                <>
                  <Box className={classes.readLess}>
                    {i18next.language === LanguageCodes.ENGLISH
                      ? audit.englishTranslation[0]?.mainFindings ||
                        t("notAvailable")
                      : audit.portugueseTranslation[0]?.mainFindings ||
                        t("notAvailable")}
                  </Box>
                  <Button
                    onClick={handleMainFindingsReadMore}
                    className={classes.buttonText}
                  >
                    <Typography variant="body1">{t("readMore")}</Typography>
                  </Button>
                </>
              ) : (
                <>
                  <Box>
                    {i18next.language === LanguageCodes.ENGLISH
                      ? audit.englishTranslation[0]?.mainFindings ||
                        t("notAvailable")
                      : audit.portugueseTranslation[0]?.mainFindings ||
                        t("notAvailable")}
                  </Box>
                  {mainFindingsReadMore && (
                    <Button
                      onClick={handleMainFindingsReadMore}
                      className={classes.buttonText}
                    >
                      <Typography variant="body1">{t("readLess")}</Typography>
                    </Button>
                  )}
                </>
              )}
            </Typography>
            <Typography variant="h5" className={classes.summaryHeader}>
              {t("professionalOpinion")}
            </Typography>
            <Typography variant="body1" className={classes.executiveSummary}>
              {!professionalOpinionReadMore &&
              (audit.englishTranslation[0]?.professionalOpinion ||
                audit.portugueseTranslation[0]?.professionalOpinion) &&
              (audit.englishTranslation[0]?.professionalOpinion.length ||
                audit.portugueseTranslation[0]?.professionalOpinion.length) >
                500 ? (
                <>
                  <Box className={classes.readLess}>
                    {i18next.language === LanguageCodes.ENGLISH
                      ? audit.englishTranslation[0]?.professionalOpinion ||
                        t("notAvailable")
                      : audit.portugueseTranslation[0]?.professionalOpinion ||
                        t("notAvailable")}
                  </Box>
                  <Button
                    onClick={handleProfessionalOpinionReadMore}
                    className={classes.buttonText}
                  >
                    <Typography variant="body1">{t("readMore")}</Typography>
                  </Button>
                </>
              ) : (
                <>
                  <Box>
                    {i18next.language === LanguageCodes.ENGLISH
                      ? audit.englishTranslation[0]?.professionalOpinion ||
                        t("notAvailable")
                      : audit.portugueseTranslation[0]?.professionalOpinion ||
                        t("notAvailable")}
                  </Box>
                  {professionalOpinionReadMore && (
                    <Button
                      onClick={handleProfessionalOpinionReadMore}
                      className={classes.buttonText}
                    >
                      <Typography variant="body1">{t("readLess")}</Typography>
                    </Button>
                  )}
                </>
              )}
            </Typography>
            <Typography variant="h5" className={classes.summaryHeader}>
              {t("actionsRecommendations")}
            </Typography>
            <Typography variant="body1" className={classes.executiveSummary}>
              {!actionsRecommendationsReadMore &&
              (audit.englishTranslation[0]?.actionsRecommendations ||
                audit.portugueseTranslation[0]?.actionsRecommendations) &&
              (audit.englishTranslation[0]?.actionsRecommendations.length ||
                audit.portugueseTranslation[0]?.actionsRecommendations.length) >
                500 ? (
                <>
                  <Box className={classes.readLess}>
                    {i18next.language === LanguageCodes.ENGLISH
                      ? audit.englishTranslation[0]?.actionsRecommendations ||
                        t("notAvailable")
                      : audit.portugueseTranslation[0]
                          ?.actionsRecommendations || t("notAvailable")}
                  </Box>
                  <Button
                    onClick={handleActionsRecommendationsReadMore}
                    className={classes.buttonText}
                  >
                    <Typography variant="body1">{t("readMore")}</Typography>
                  </Button>
                </>
              ) : (
                <>
                  <Box>
                    {i18next.language === LanguageCodes.ENGLISH
                      ? audit.englishTranslation[0]?.actionsRecommendations ||
                        t("notAvailable")
                      : audit.portugueseTranslation[0]
                          ?.actionsRecommendations || t("notAvailable")}
                  </Box>
                  {actionsRecommendationsReadMore && (
                    <Button
                      onClick={handleActionsRecommendationsReadMore}
                      className={classes.buttonText}
                    >
                      <Typography variant="body1">{t("readLess")}</Typography>
                    </Button>
                  )}
                </>
              )}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h6" className={classes.executiveSummaryHeader}>
              {t("detailsTitle")}
            </Typography>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <b>{t("score")}</b>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">{audit.auditScore}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <b>{t("startDate")}</b>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {audit.startDate
                    ? moment(audit.startDate).format(
                        DateConstants.DEFAULT_DATE_FORMAT,
                      )
                    : t("notAvailable")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <b>{t("endDate")}</b>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {audit.endDate
                    ? moment(audit.endDate).format(
                        DateConstants.DEFAULT_DATE_FORMAT,
                      )
                    : t("notAvailable")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <b>{t("leadAuditor")}</b>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {audit.leadAuditor.fullName}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <b>{t("fpsos:subSystems") + ":"}</b>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <div>
                    {audit.itemsWithSubSystems.map(subSystem => (
                      <Paper
                        className={classes.subSystemItem}
                        variant="outlined"
                      >
                        {t("fpsos:" + subSystem.subSystemType)}
                      </Paper>
                    ))}
                  </div>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Snackbar
        open={videoSessionStartSuccessOpen}
        autoHideDuration={TimeConstants.SNACKBAR_ALERT_DURATION}
        onClose={handleVideoSessionStartSuccessClose}
      >
        <Alert onClose={handleVideoSessionStartSuccessClose} severity="success">
          {t("audits:videoSessionStartSuccess")}
        </Alert>
      </Snackbar>

      <Snackbar
        open={videoSessionStartFailureOpen}
        autoHideDuration={TimeConstants.SNACKBAR_ALERT_DURATION}
        onClose={handleVideoSessionStartFailureClose}
      >
        <Alert onClose={handleVideoSessionStartFailureClose} severity="error">
          {t("audits:videoSessionStartFailure")}
        </Alert>
      </Snackbar>

      <Snackbar
        open={videoSessionEndSuccessOpen}
        autoHideDuration={TimeConstants.SNACKBAR_ALERT_DURATION}
        onClose={handleVideoSessionEndSuccessClose}
      >
        <Alert onClose={handleVideoSessionEndSuccessClose} severity="success">
          {t("audits:videoSessionEndSuccess")}
        </Alert>
      </Snackbar>

      <Snackbar
        open={videoSessionEndFailureOpen}
        autoHideDuration={TimeConstants.SNACKBAR_ALERT_DURATION}
        onClose={handleVideoSessionEndFailureClose}
      >
        <Alert onClose={handleVideoSessionEndFailureClose} severity="error">
          {t("audits:videoSessionEndFailure")}
        </Alert>
      </Snackbar>
    </Paper>
  );
};
