import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IndeterminateCheckBoxOutlinedIcon from "@material-ui/icons/IndeterminateCheckBoxOutlined";
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import { useKeycloak } from "@react-keycloak/web";
import ANPGLogo from "Assets/Images/ANPGLogo.png";
import ElementSizes from "Constants/ElementSizes";
import Routes from "Constants/Routes";
import { Roles } from "Enums/Roles";
import { Block } from "Models/Block";
import { RouteParams } from "Models/RouteParams";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import uuid from "uuid";
import { ArchivedAuditIcon } from "Views/Components/Icons/ArchivedAuditIcon";
import { CurrentAuditIcon } from "Views/Components/Icons/CurrentAuditIcon";
import { SiteDocumentsIcon } from "Views/Components/Icons/SiteDocumentsIcon";
import { CarDetailsIcon } from "../Icons/CarDetailsIcon";

interface Props {
  readonly blocks: Block[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: ElementSizes.DRAWER_WIDTH,
    },
    profileSection: {
      justifyContent: "space-between",
      display: "flex",
      paddingBottom: theme.spacing(2),
    },
    navigationSection: {
      justifyContent: "space-between",
      display: "flex",
      padding: theme.spacing(2),
      color: "#ffffff",
      textAlign: "center",
      paddingLeft: theme.spacing(6),
    },
    navigationBox: {
      flexGrow: 1,
      width: ElementSizes.DRAWER_WIDTH,
    },
    treeViewSection: {
      paddingLeft: theme.spacing(2),
    },
    treeBlockItemSection: {
      paddingTop: theme.spacing(0.625),
    },
    image: {
      height: ElementSizes.LOGO_HEIGHT,
      marginLeft: "auto",
      marginRight: "auto",
      width: "50%",
      display: "block",
    },
    archiveIcon: {
      width: 40,
      height: 50,
    },
    currentIcon: {
      width: 40,
      height: 50,
      paddingLeft: theme.spacing(0.5),
    },
    siteDocumentsIcon: {
      width: 40,
      height: 50,
    },
    detailsIcon: {
      width: 40,
      height: 50,
    },
    labelRoot: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0.5, 0),
    },
    labelText: {
      fontWeight: "inherit",
      flexGrow: 1,
    },
  }),
);

export const BlockTreeView: FC<Props> = ({ blocks }) => {
  const classes = useStyles();
  const { t } = useTranslation("blocks");
  const { keycloak } = useKeycloak();

  const fpsoId = useRouteMatch<RouteParams>(Routes.FPSO)?.params.fpsoId;
  const blockId = useRouteMatch<RouteParams>(Routes.BLOCK)?.params.blockId;
  const fpsoAreaId = useRouteMatch<RouteParams>(Routes.FPSO_AREA)?.params
    .fpsoAreaId;

  const location = useLocation();
  const history = useHistory();

  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);

  const initializeExpandedNodes = useCallback(
    (
      currentFpsoId: string | undefined,
      currentBlockId: string | undefined,
      currentFpsoAreaId: string | undefined,
      currentPath: string,
    ) => {
      const initializedNodes: string[] = [];

      const pathSuffix = currentPath.split("/").pop();
      if (
        pathSuffix &&
        [Routes.GRAPHICAL_RESULTS_BASEPATH, Routes.KPI_TABLE_BASEPATH].includes(
          pathSuffix,
        )
      ) {
        setExpandedNodes([Routes.MANAGEMENT_SUMMARY_BASEPATH]);
        return;
      }

      if (!currentFpsoId && !currentBlockId && !currentFpsoAreaId) {
        setExpandedNodes([]);
        return;
      }

      if (currentFpsoId) {
        const blockIdByFpsoId = blocks.find(block =>
          block.fpsos.find(fpso => fpso.id === currentFpsoId),
        )?.id;
        if (blockIdByFpsoId) {
          initializedNodes.push(blockIdByFpsoId, currentFpsoId);
        }
      }

      if (currentBlockId) {
        initializedNodes.push(currentBlockId);
      }

      if (currentFpsoAreaId) {
        const blockIdByFpsoId = blocks.find(block =>
          block.fpsos.find(fpso =>
            fpso.fpsoAreas.find(fpsoArea => fpsoArea.id === currentFpsoAreaId),
          ),
        )?.id;
        const fpsoIdByBlockId = blocks
          .find(block => block.id === blockIdByFpsoId)
          ?.fpsos.find(fpso =>
            fpso.fpsoAreas.find(fpsoArea => fpsoArea.id === currentFpsoAreaId),
          )?.id;

        if (blockIdByFpsoId && fpsoIdByBlockId) {
          initializedNodes.push(
            blockIdByFpsoId,
            fpsoIdByBlockId,
            currentFpsoAreaId,
          );
        }
      }

      setExpandedNodes(initializedNodes);
    },
    [blocks],
  );

  const initializeSelectedNode = useCallback(
    (currentPath: string) => {
      const pathSuffix = currentPath.split("/").pop();

      if (pathSuffix === fpsoId) {
        setSelectedNode(fpsoId ? fpsoId : "");
      } else if (pathSuffix === blockId) {
        setSelectedNode(blockId ? blockId : "");
      } else if (pathSuffix === fpsoAreaId) {
        setSelectedNode(fpsoAreaId ? fpsoAreaId : "");
      } else if (fpsoId) {
        setSelectedNode(`${fpsoId}_${pathSuffix}`);
      } else if (fpsoAreaId) {
        setSelectedNode(`${fpsoAreaId}_${pathSuffix}`);
      } else {
        setSelectedNode(pathSuffix ? pathSuffix : "");
      }
    },
    [fpsoAreaId, fpsoId, blockId],
  );

  const [selectedNode, setSelectedNode] = useState<string>("");

  useEffect(() => {
    initializeExpandedNodes(fpsoId, blockId, fpsoAreaId, location.pathname);
    initializeSelectedNode(location.pathname);
  }, [
    location.pathname,
    fpsoAreaId,
    fpsoId,
    blockId,
    initializeExpandedNodes,
    initializeSelectedNode,
  ]);

  const handleNodeToggle = (event: React.ChangeEvent<{}>, nodes: string[]) => {
    setExpandedNodes(expandedNodes.concat(nodes));
  };

  const handleNodeSelect = (event: React.ChangeEvent<{}>, nodeId: string) => {
    setSelectedNode(nodeId);
  };

  const handleBlockClick = (selectedBlockId: string) => {
    window.scrollTo(0, 0);
    history.push(`/block/${selectedBlockId}`);
  };

  const handleFpsoClick = (selectedFpsoId: string) => {
    window.scrollTo(0, 0);
    history.push(`/fpso/${selectedFpsoId}`);
  };

  const handleFpsoAreaClick = (selectedFpsoAreaId: string) => {
    window.scrollTo(0, 0);
    history.push(`/fpsoArea/${selectedFpsoAreaId}`);
  };

  const handleSystemDetailsClick = (selectedFpsoAreaId: string) => {
    window.scrollTo(0, 0);
    history.push(
      `/fpsoArea/${selectedFpsoAreaId}/${Routes.SYSTEM_DETAILS_BASEPATH}`,
    );
  };

  const handleCurrentAuditClick = (selectedFpsoAreaId: string) => {
    window.scrollTo(0, 0);
    if (selectedFpsoAreaId) {
      history.push(
        `/fpsoArea/${selectedFpsoAreaId}/${Routes.CURRENT_AUDIT_BASEPATH}`,
      );
    } else {
      history.push(`/fpsoArea/${uuid()}/${Routes.CURRENT_AUDIT_BASEPATH}`);
    }
  };

  const handleArchivedAuditsClick = (selectedFpsoAreaId: string) => {
    window.scrollTo(0, 0);
    history.push(
      `/fpsoArea/${selectedFpsoAreaId}/${Routes.ARCHIVED_AUDITS_BASEPATH}`,
    );
  };

  const handleFpsoDocumentsClick = (selectedFpsoAreaId: string) => {
    window.scrollTo(0, 0);
    history.push(
      `/fpsoArea/${selectedFpsoAreaId}/${Routes.DOCUMENTS_BASEPATH}`,
    );
  };

  const handleCollapseAllClick = () => {
    setExpandedNodes([]);
  };

  const handleGraphicalResultsClick = () => {
    window.scrollTo(0, 0);
    history.push(Routes.GRAPHICAL_RESULTS);
  };

  const handleKpiTableClick = () => {
    window.scrollTo(0, 0);
    history.push(Routes.KPI_TABLE);
  };

  const handleUserFeedbackClick = () => {
    window.scrollTo(0, 0);
    history.push(Routes.USER_FEEDBACK);
  };

  return (
    <div className={classes.root}>
      <Tooltip title={t<string>("menu:anpgWebsite")} arrow={true}>
        <a href="https://anpg.co.ao/" target="_blank" rel="noopener noreferrer">
          <img className={classes.image} src={ANPGLogo} alt="ANPG Logo" />
        </a>
      </Tooltip>
      <Box bgcolor="primary.main">
        <Typography variant="h6" className={classes.navigationSection}>
          {t("title")}
          <IconButton size="small" onClick={() => handleCollapseAllClick()}>
            <IndeterminateCheckBoxOutlinedIcon style={{ color: "#ffffff" }} />
          </IconButton>
        </Typography>
      </Box>
      <TreeView
        expanded={expandedNodes}
        selected={selectedNode}
        onNodeToggle={handleNodeToggle}
        onNodeSelect={handleNodeSelect}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        className={classes.treeViewSection}
      >
        {blocks.map(block => (
          <TreeItem
            nodeId={block.id}
            key={block.id}
            label={t("block") + " " + block.name}
            onClick={() => handleBlockClick(block.id)}
            className={classes.treeBlockItemSection}
          >
            {block.fpsos.map(fpso => (
              <TreeItem
                nodeId={fpso.id}
                label={fpso.name}
                key={fpso.id}
                onClick={() => handleFpsoClick(fpso.id)}
                className={classes.treeBlockItemSection}
              >
                {fpso.fpsoAreas.map(fpsoArea => (
                  <>
                    {(fpsoArea.areaName === "Hydrocarbon Export" ||
                      fpsoArea.areaName === "Management") && (
                      <TreeItem
                        nodeId={fpsoArea.id}
                        label={fpsoArea.areaName}
                        key={fpsoArea.id}
                        onClick={() => handleFpsoAreaClick(fpsoArea.id)}
                        className={classes.treeBlockItemSection}
                      >
                        {fpsoArea.areaName !== "Management" ? (
                          <TreeItem
                            nodeId={`${fpsoArea.id}_${Routes.SYSTEM_DETAILS_BASEPATH}`}
                            key={`${fpsoArea.id}_${Routes.SYSTEM_DETAILS_BASEPATH}`}
                            label={
                              <div className={classes.labelRoot}>
                                <div className={classes.detailsIcon}>
                                  <CarDetailsIcon />
                                </div>
                                <Typography
                                  variant="body2"
                                  className={classes.labelText}
                                >
                                  {t("systemDetails")}
                                </Typography>
                              </div>
                            }
                            onClick={() =>
                              handleSystemDetailsClick(fpsoArea.id)
                            }
                          />
                        ) : (
                          <></>
                        )}
                        <TreeItem
                          nodeId={`${fpsoArea.id}_${Routes.CURRENT_AUDIT_BASEPATH}`}
                          key={`${fpsoArea.id}_${Routes.CURRENT_AUDIT_BASEPATH}`}
                          label={
                            <div className={classes.labelRoot}>
                              <div className={classes.currentIcon}>
                                <CurrentAuditIcon />
                              </div>
                              <Typography
                                variant="body2"
                                className={classes.labelText}
                              >
                                {t("currentAudit")}
                              </Typography>
                            </div>
                          }
                          onClick={() => handleCurrentAuditClick(fpsoArea.id)}
                        />
                        <TreeItem
                          nodeId={`${fpsoArea.id}_${Routes.ARCHIVED_AUDITS_BASEPATH}`}
                          key={`${fpsoArea.id}_${Routes.ARCHIVED_AUDITS_BASEPATH}`}
                          label={
                            <div className={classes.labelRoot}>
                              <div className={classes.archiveIcon}>
                                <ArchivedAuditIcon />
                              </div>
                              <Typography
                                variant="body2"
                                className={classes.labelText}
                              >
                                {t("archivedAudits")}
                              </Typography>
                            </div>
                          }
                          onClick={() => handleArchivedAuditsClick(fpsoArea.id)}
                        />
                        <TreeItem
                          nodeId={`${fpsoArea.id}_${Routes.DOCUMENTS_BASEPATH}`}
                          key={`${fpsoArea.id}_${Routes.DOCUMENTS_BASEPATH}`}
                          label={
                            <div className={classes.labelRoot}>
                              <div className={classes.siteDocumentsIcon}>
                                <SiteDocumentsIcon />
                              </div>
                              <Typography
                                variant="body2"
                                className={classes.labelText}
                              >
                                {t("areaDocuments")}
                              </Typography>
                            </div>
                          }
                          onClick={() => handleFpsoDocumentsClick(fpsoArea.id)}
                        />
                      </TreeItem>
                    )}
                  </>
                ))}
              </TreeItem>
            ))}
          </TreeItem>
        ))}
        {(keycloak?.hasRealmRole(Roles.auditor) ||
          keycloak?.hasRealmRole(Roles.administrator) ||
          keycloak?.hasRealmRole(Roles.agency) ||
          keycloak?.hasRealmRole(Roles.agency_v2)) && (
          <TreeItem
            nodeId={Routes.MANAGEMENT_SUMMARY_BASEPATH}
            key={Routes.MANAGEMENT_SUMMARY_BASEPATH}
            label={t("managementSummary")}
            className={classes.treeBlockItemSection}
          >
            <TreeItem
              nodeId={Routes.KPI_TABLE_BASEPATH}
              key={Routes.KPI_TABLE_BASEPATH}
              label={t("actualAuditStatus")}
              onClick={() => handleKpiTableClick()}
            />
            <TreeItem
              nodeId={Routes.GRAPHICAL_RESULTS_BASEPATH}
              key={Routes.GRAPHICAL_RESULTS_BASEPATH}
              label={t("graphicalResults")}
              onClick={() => handleGraphicalResultsClick()}
            />
          </TreeItem>
        )}
        <TreeItem
          nodeId={Routes.USER_FEEDBACK_BASEPATH}
          key={Routes.USER_FEEDBACK_BASEPATH}
          label={t("userFeedback")}
          className={classes.treeBlockItemSection}
          onClick={() => handleUserFeedbackClick()}
        />
      </TreeView>
    </div>
  );
};
